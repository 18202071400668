import React, { useState, useRef, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { IBookChapter, IBookData } from 'Components/books/types';
import { ReactComponent as ArrowIcon } from 'Assets/arrow-left.svg';
import s from './BookGenerator.module.scss';
import Input from 'Components/books/Input/Input';
import TextArea from 'Components/books/TextArea/TextArea';
import Select from 'Components/books/Select/Select';
import Button from 'Components/Common/Button/Button';
import { graphQlCall } from 'graphql/utils';
import queries from 'graphql/queries';
import { Socket } from 'socket.io-client';
import BookInfoBlock from 'Components/books/BookInfoBlock/BookInfoBlock';
import { fetchSaasDesign } from 'rx/actions/rxFetchSaasDesign';
import { fetchSectionsMenu } from 'rx/actions/rxFetchSectionsMenu';
import { generateBookCoverThumbnail, getDomain } from '../utils/functions';
import { rxChapterIndexForDelete, rxSelectBookImg } from 'rx/rxState';
import { getBySocket, createSocket } from 'utils/socket';
import { getToken } from 'utils/api';
import InappropriateAIContentPopup from 'Components/Popups/InappropriateAIContentPopup';
import Logo from 'Components/Logo/Logo';
import { ReactComponent as ArrowLeft } from '../Assets/arrow-left-back.svg';
import clsx from 'clsx';
import LoadingSpinner from 'Components/Common/LoadingSpinner/LoadingSpinner';
import BookCover from './BookCover';

const aviableTones = [
  'Professional',
  'Semi-Professional',
  'Relaxed',
  'Technical',
  'Custom',
  '<split>',
  'Conversational',
  'Apathetic',
  'Australian English',
  'Bittersweet',
  'British English',
  'Casual',
  'Cynical',
  'Dark',
  'Dispassionate',
  'Enthusiastic',
  'Formal',
  'Gothic',
  'Hopeful',
  'Humorous',
  'Ironic',
  'Melancholic',
  'Mysterious',
  'Nostalgic',
  'Optimistic',
  'Provocative',
  'Regretful',
  'Sarcastic',
  'Satirical',
  'Sentimental',
  'Serious',
  'Sincere',
  'Uplifting',
  'Whimsical',
  'Witty',
];

function extractChapterTitle(string: string) {
  const chapterRegex = /^(?:\w+\s+)*Chapter\s+(\d+):?\s+(.+)/;
  const match = string.match(chapterRegex);

  if (match) {
    return match[2];
  } else {
    return string;
  }
}

const BookGenerator = () => {
  let history = useHistory();

  const [customTone, setCustomTone] = useState('');
  const [customToneEnabled, setCustomToneEnabled] = useState(false);
  const [bookTitle, setBookTitle] = useState('');
  const [bookDescription, setBookDescription] = useState('');
  const [tone, setTone] = useState<string>('');
  const [bookData, setBookData] = useState<IBookData | null>(null);

  const [titleHasError, setTitleHasError] = useState(false);
  // const [toneHasError, setToneHasError] = useState(false);
  const [descriptionHasError, setDescriptionHasError] = useState(false);
  const [customToneHasError, setCustomToneHasError] = useState(false);

  const [bookAudience, setBookAudience] = useState('');
  const [bookStructure, setBookStructure] = useState('');
  const [bookAudienceHasError, setBookAudienceHasError] = useState(false);
  const [bookStructureHasError, setBookStructureHasError] = useState(false);
  const [step, setStep] = useState<number>(1);
  const [isBookCoverCollapsed, setIsBookCoverCollapsed] = useState(false);

  const [loading, setLoading] = useState(false);
  const [loadingChapter, setLoadingChapter] = useState(false);
  const [loadingAddChapter, setLoadingAddChapter] = useState(false);

  const [bookImg, setBookImg] = useState<string[]>([]);
  const bookImageGenerationTimeout = useRef<ReturnType<typeof setTimeout>>();
  const [selectBookImg, setSelectBookImg] = useState<string | undefined>();

  const chapterPositionForGql = useRef<number[]>([]);
  const socket = useRef<Socket | null>(null);

  const [bookCoverTitles, setBookCoverTitles] = useState<string[]>([]);

  const [socketStatus, setSocketStatus] = useState('closed');
  const token = getToken();

  const [bookCoverEditor, setBookCoverEditor] = useState(false);

  const bookCoverData = useRef<any>(null);

  // useEffect(() => {
  //   if (!selectBookImg) {
  //     setSelectBookImg(bookImg[0]);
  //   }
  // }, [bookImg]);

  // useEffect(() => {
  //   if (selectBookImg) {
  //     rxSelectBookImg.next(selectBookImg);
  //   }
  // }, [selectBookImg]);

  useEffect(() => {
    if (!getToken()) {
      console.error('no user token');
      // const url = `/console/login`;
      // window.open(url, "_self");
    }

    if (socketStatus === 'closed') {
      socket.current = createSocket();
      socket.current.on('connect', () => {
        console.log('connect');

        setSocketStatus('connected');
      });
    }

    fetchSaasDesign();
  }, []);

  useEffect(() => {
    if (bookData && socket.current) {
      socket.current.on('open-ai-image-generated', (data) => {
        if (data.imageUrls.length) {
          setBookImg(data.imageUrls);
          clearTimeout(bookImageGenerationTimeout.current);
          socket.current!.off('connect', () => {});
        }
      });
    }
  }, [bookData]);

  const generateBookTableOfContext = async () => {
    if (!socket.current) {
      return;
    }

    let toneData = tone;
    if (customToneEnabled) {
      toneData = customTone;
    }

    const payload: any = {
      title: bookTitle,
      description: bookDescription,
      tone: toneData,
      token: getToken(),
    };

    if (bookAudience?.length) {
      payload.audience = bookAudience;
    }
    if (bookStructure?.length) {
      payload.structure = bookStructure;
    }

    const data: any = await getBySocket({
      emitEventName: 'new-book-table-content',
      resultEventName: 'response-book-table-content',
      payload,
      socket: socket.current,
    });
    const newBookData: IBookData = { ...bookData } as IBookData;
    const chapters = data.chapters.map((chapter: string, index: number) => {
      chapterPositionForGql.current.push(index);
      return {
        title: chapter,
        text: '',
        comments: [],
      };
    });
    newBookData.chapters = chapters as IBookChapter[];
    setBookData(newBookData);
    setLoadingChapter(false);
  };

  const generateTextWithPrompt = async (text: string) => {
    if (!socket.current) {
      return '';
    }

    const payload = { text };
    const response: any = await getBySocket({
      emitEventName: 'ai-single-prompt',
      resultEventName: 'response-ai-single-prompt',
      payload,
      socket: socket.current,
    });
    return response.result.text;
  };

  const generateBookCoverTitles = async (
    title: string,
    description: string
  ) => {
    if (socket.current) {
      // const titlePrompt = `Came up with a three words title for my book abot "${title}", "${description}". write only title`;
      // const titleReponse = await generateTextWithPrompt(titlePrompt);

      const subtitle1Prompt = `Write very short slogan for my book that titled "${title}" with description "${description}"`;
      const subTitle1Response = await generateTextWithPrompt(subtitle1Prompt);
      const subtitle2Prompt = `Write one sentence summary for my book that titled "${title}" with description "${description}"`;
      const subTitle2Response = await generateTextWithPrompt(subtitle2Prompt);

      let coverTitles = [title, subTitle1Response, subTitle2Response];
      coverTitles = coverTitles.map((title) => {
        return title.replaceAll('"', '');
      });
      setBookCoverTitles(coverTitles);
    }
  };

  const handleToneInput = (value: string) => {
    setTone(value);
    setCustomToneEnabled(value === 'Custom');
  };

  const handleCustomToneInput = (value: string) => {
    setCustomToneHasError(false);
    setCustomTone(value);
  };

  const handleNameInput = (value: string) => {
    setTitleHasError(false);
    const words = value.split(' ');
    const capitalizedWords = words.map((word) => {
      if (word.length > 0) {
        return word[0].toUpperCase() + word.slice(1);
      }
      return word;
    });
    const capitalizedValue = capitalizedWords.join(' ');
    setBookTitle(capitalizedValue);
  };

  const handleDescriptionInput = (value: string) => {
    setDescriptionHasError(false);
    setBookDescription(value);
  };

  const checkFields = () => {
    let valid = true;
    if (bookDescription.trim().length === 0) {
      setDescriptionHasError(true);
      valid = false;
    }

    if (bookTitle.trim().length === 0) {
      setTitleHasError(true);
      valid = false;
    }

    if (customToneEnabled && customTone.trim().length === 0) {
      setCustomToneHasError(true);
    }
    return valid;
  };

  const handleNext = async () => {
    if (step === 1) {
      setBookData({
        audience: bookAudience,
        chapters: [],
        description: bookDescription,
        structure: bookStructure,
        title: bookTitle,
        tone: tone || '',
      });
      if (!checkFields()) {
        return;
      }
      setStep(2);
    } else if (step === 2) {
      if (!tone) {
        setTone('Semi-Professional');
      }
      setStep(3);
      if (bookAudience.trim() || bookStructure.trim()) {
        addAudienceAndStructure();
      } else {
        skipAddAudienceAndStructure();
      }
    }
  };

  const addAudienceAndStructure = async () => {
    if (!socket.current) {
      return;
    }

    setLoading(true);
    setLoadingChapter(true);
    await generateBookTableOfContext();
    await generateBookCoverTitles(bookTitle, bookDescription);

    generateBookCoverByAI(bookTitle);
    setLoading(false);
  };

  const generateBookCoverByAI = (text: string) => {
    const connectAndSendMessage = () => {
      socket.current?.emit('generate-openai-image', {
        text: text,
        ratio: '1:1',
        imageCount: 3,
        bookId: bookData?._id,
      });
      const timeout = setTimeout(() => {
        console.log('cover image generation timeout');
        const placeholders: string[] = [];
        for (let i = 0; i < 3; i++) {
          placeholders.push(
            'https://cdn.autofunnel.ai/covers/placeholder2.png'
          );
        }
        setBookImg(placeholders);
      }, 60000);
      bookImageGenerationTimeout.current = timeout;
    };
    socket.current!.on('connect', connectAndSendMessage);
    connectAndSendMessage();
  };

  const skipAddAudienceAndStructure = async () => {
    setLoadingChapter(true);
    setLoading(true);
    await generateBookTableOfContext();
    await generateBookCoverTitles(bookTitle, bookDescription);

    generateBookCoverByAI(bookTitle);
    setLoading(false);
  };

  const handleBookAudienceInput = (value: string) => {
    setBookAudienceHasError(false);
    setBookAudience(value);
  };

  const handleBookStructureInput = (value: string) => {
    setBookStructureHasError(false);
    setBookStructure(value);
  };

  const onBookTitleEdited = async (newTitle: string) => {
    if (newTitle.trim().length === 0) {
      return;
    }

    if (bookData) {
      setBookData({
        ...bookData,
        title: newTitle,
      });
    }
  };

  const onChapterTitleEdited = async (value: string, index: number) => {
    if (value.trim().length === 0) {
      return;
    }
    if (bookData) {
      const newBookData = { ...bookData };
      newBookData.chapters[index].title = value;
      setBookData(newBookData);
    }
  };

  const onChapterAdd = async (
    indexBefore: number,
    action: 'add' | 'insert'
  ) => {
    if (bookData) {
      setLoadingAddChapter(true);
      await addChapterBySocket(action, indexBefore);
    }
  };

  const addChapterBySocket = async (action: string, indexBefore?: number) => {
    if (!socket.current || !bookData) {
      return;
    }
    let payloadAction = action;
    if (indexBefore == bookData.chapters.length) {
      payloadAction = 'add';
    }
    const payload = {
      token: token,
      title: bookTitle,
      size: 'SMALL',
      positionBefore: indexBefore,
      chapters: bookData.chapters.map((chapter) => chapter.title),
      action: payloadAction,
    };
    const data: any = await getBySocket({
      emitEventName: 'new-book-add-chapter',
      resultEventName: 'response-book-add-chapter',
      payload,
      socket: socket.current,
    });
    const newBookData = { ...bookData } as IBookData;

    const newChapterPositionForGql: number[] = [];
    const newChapters: IBookChapter[] = [];
    bookData.chapters.forEach((chapter: IBookChapter, index: number) => {
      if (indexBefore !== undefined && index === indexBefore) {
        newChapters.push({
          comments: [],
          text: '',
          title: extractChapterTitle(data.chapterTitle as string),
        });
      }
      newChapters.push(chapter);
    });

    if (payloadAction === 'add') {
      newChapters.push({
        comments: [],
        text: '',
        title: extractChapterTitle(data.chapterTitle as string),
      });
    }

    newBookData.chapters = newChapters;
    newBookData.chapters.forEach((el: any, index) => {
      newChapterPositionForGql.push(index);
    });
    setBookData(newBookData);
    chapterPositionForGql.current = newChapterPositionForGql;

    let toneData = tone;
    if (customToneEnabled) {
      toneData = customTone;
    }

    const createBookPayload: any = {
      title: bookTitle,
      description: bookDescription,
      tone: toneData,
      size: 'SMALL',
      chapters: JSON.stringify(newChapters),
    };

    if (bookAudience?.length) {
      createBookPayload.audience = bookAudience;
    }
    if (bookStructure?.length) {
      createBookPayload.audience = bookStructure;
    }

    setLoadingAddChapter(false);
  };

  const deleteChapter = async (index: number) => {
    rxChapterIndexForDelete.next(index as any);
    if (bookData) {
      const newBookData = { ...bookData };
      const newChapters = [...newBookData.chapters];
      newChapters.splice(index, 1);
      newBookData.chapters = newChapters;

      setBookData(newBookData);

      const newChapterPositionForGql: number[] = [];
      newBookData.chapters.forEach((el: any, index) => {
        newChapterPositionForGql.push(index);
      });
      chapterPositionForGql.current = newChapterPositionForGql;
    }
  };

  const reorderChapter = (dragIndex: number, dropIndex: number) => {
    if (bookData) {
      const newBookData = { ...bookData };
      let newChapters = [...newBookData.chapters];
      const tempChapter = newChapters.splice(dragIndex, 1);
      newChapters.splice(dropIndex, 0, tempChapter[0]);
      newBookData.chapters = newChapters;
      setBookData(newBookData);

      const newChapterPositionForGql = [...chapterPositionForGql.current];
      const tempChapterIndex = newChapterPositionForGql.splice(dragIndex, 1);
      newChapterPositionForGql.splice(dropIndex, 0, tempChapterIndex[0]);
      chapterPositionForGql.current = newChapterPositionForGql;
    }
  };

  const onNoteAdd = async (chapterIndex: number, noteText: string) => {
    if (bookData) {
      const newBookData = { ...bookData };
      newBookData.chapters[chapterIndex].comments = [noteText];
      setBookData(newBookData);
    }
  };

  const handleGenerateBook = async (value: string | undefined) => {
    if (!bookData) return;

    let toneData = tone;
    if (customToneEnabled) {
      toneData = customTone;
    }
    const createData: any = {
      title: bookData.title,
      description: bookDescription,
      tone: toneData,
      size: value,
      chapters: JSON.stringify(bookData.chapters),
    };

    if (bookAudience.length) {
      createData.audience = bookAudience;
    }
    if (bookStructure.length) {
      createData.structure = bookStructure;
    }
    if (selectBookImg?.length) {
      rxSelectBookImg.next(selectBookImg);
      createData.coverImageUrl = selectBookImg;
    }

    if (bookCoverData.current) {
      createData.coverData = JSON.stringify(bookCoverData.current);
    }

    const cover64 = await generateBookCoverThumbnail(400);
    if (cover64) {
      createData.coverImage = cover64;
    }

    const createdBookData: any = await graphQlCall({
      queryTemplateObject: queries.CREATE_BOOK_MUTATION,
      headerType: 'USER-AUTH',
      values: createData,
    });
    const bookId = createdBookData._id;

    socket.current?.emit('ai-book-all-chapters', {
      bookId,
    });
    history.push(`/edit/book-editor/${bookId}`);
  };

  const handleBookCoverEditorToggle = () => {
    setBookCoverEditor(!bookCoverEditor);
  };

  useEffect(() => {
    fetchSectionsMenu();
  }, []);

  const redirectToBookleMenu = () => {
    const { domain } = getDomain();
    window.open(`//${domain}/console/books`, '_self');
  };

  const handleCoverChange = (coverData: any) => {
    if (bookCoverTitles && coverData) {
      coverData.titles = bookCoverTitles; //TODO: super weird workaround. need to refactor how we handle titles
    }
    bookCoverData.current = coverData;
  };

  if (step === 1) {
    return (
      <div className={s.mainBookPageGeneratorContainer}>
        <span className={s.backButton} onClick={redirectToBookleMenu}>
          <ArrowLeft fill="black" />
          Exit
        </span>
        <div className={s.bookPageContent}>
          <div className={s.bookPageLogoBlock}>
            <Logo />
          </div>
          {loading ? (
            <LoadingSpinner />
          ) : (
            <div className={s.inputsBlock}>
              <h2 className={s.bookPageHeader}>{'Tell us about your book!'}</h2>
              <Input
                value={bookTitle}
                label="Book Title"
                onChange={(value) => handleNameInput(value)}
                hasErrors={titleHasError}
                autoComplete="off"
                maxLength={150}
              />
              <TextArea
                value={bookDescription}
                label="Book Description"
                onChange={(value) => handleDescriptionInput(value)}
                hasErrors={descriptionHasError}
                autoComplete="off"
                maxLength={5000}
              />
              <div className={s.buttonBlock}>
                <Button
                  size="regular"
                  onClick={handleNext}
                  borderRadius={'25px'}
                >
                  Next
                </Button>
              </div>
            </div>
          )}
          <InappropriateAIContentPopup />
        </div>
      </div>
    );
  } else if (step === 2) {
    return (
      <div className={s.mainBookPageGeneratorContainer}>
        <span className={s.backButton} onClick={redirectToBookleMenu}>
          <ArrowLeft fill="black" />
          Exit
        </span>

        <div className={s.bookPageContent}>
          <div className={s.bookPageLogoBlock}>
            <Logo />
          </div>
          {loading ? (
            <div className={s.spinter}>
              <LoadingSpinner />
            </div>
          ) : (
            <div>
              <h2 className={s.bookPageHeader}>{'Audience and Structure'}</h2>
              <div className={s.inputsBlock}>
                <Input
                  value={bookAudience}
                  label="Who is your audience? (Optional)"
                  onChange={(value) => handleBookAudienceInput(value)}
                  hasErrors={bookAudienceHasError}
                  autoComplete="off"
                  maxLength={1000}
                />
                <Select
                  value={tone}
                  label="Writing Tone (Optional)"
                  onChange={(value) => handleToneInput(value)}
                  options={aviableTones}
                  hasErrors={false}
                />
                {customToneEnabled && (
                  <Input
                    value={customTone}
                    label="Custom Tone"
                    onChange={(value) => handleCustomToneInput(value)}
                    hasErrors={customToneHasError}
                    autoComplete="off"
                    maxLength={150}
                  />
                )}
                <div className={s.textAreaWrapper}>
                  <TextArea
                    value={bookStructure}
                    label="Structure (Optional)"
                    onChange={(value) => handleBookStructureInput(value)}
                    hasErrors={bookStructureHasError}
                    autoComplete="off"
                    maxLength={5000}
                  />
                </div>
                <div className={s.buttonsBlock}>
                  <Button
                    size="regular"
                    color="secondary"
                    onClick={() => setStep(1)}
                    borderRadius={'25px'}
                  >
                    Back
                  </Button>
                  <Button
                    size="regular"
                    onClick={handleNext}
                    borderRadius={'25px'}
                  >
                    Next
                  </Button>
                </div>
              </div>
            </div>
          )}
          <InappropriateAIContentPopup />
        </div>
      </div>
    );
  } else if (step === 3) {
    return (
      <div className={clsx(s.mainBookPageGeneratorContainer)}>
        <div
          className={clsx(s.bookPageGeneratorWrapper, {
            [s.bookCoverSlide]: bookCoverEditor,
          })}
        >
          {!loading && (
            <div
              className={clsx(s.collapseBlock, {
                [s.isCollapsed]: isBookCoverCollapsed,
              })}
              onClick={() => setIsBookCoverCollapsed(!isBookCoverCollapsed)}
            >
              {isBookCoverCollapsed ? 'Your Book Cover' : 'Collapse'}
              <ArrowIcon />
            </div>
          )}
          <div
            className={clsx(s.bookInfoBlock, {
              [s.active]: isBookCoverCollapsed,
            })}
          >
            <div className={s.bookInfoBlockInner}>
              <BookInfoBlock
                bookTitle={bookData?.title ? bookData?.title : ''}
                onBookTitleEdited={onBookTitleEdited}
                chapters={bookData?.chapters ? bookData?.chapters : []}
                onChapterTitleEdited={onChapterTitleEdited}
                deleteChapter={deleteChapter}
                loadingChapter={loadingChapter}
                loadingCover={bookImg.length === 0}
                onChapterAdd={onChapterAdd}
                reorderChapter={reorderChapter}
                loadingAddChapter={loadingAddChapter}
                onNoteAdd={onNoteAdd}
                onGenerateBook={handleGenerateBook}
              />
            </div>
          </div>
          {/* second block */}
          <div className={s.bookCoverBlock}>
            <BookCover
              titles={bookCoverTitles}
              coverImages={bookImg}
              onChange={handleCoverChange}
            />
          </div>
        </div>
        <InappropriateAIContentPopup />
      </div>
    );
  } else return null;
};

export default BookGenerator;
