import React, { Fragment } from 'react';
import styled from 'styled-components';
import { Block, BlockStyle } from './Block';
import { GROUP_TYPES } from '../../Attributes/config/constants';
import StoreController from '../../StoreController';
import emailValidator from 'email-validator';
import { rxProjectId } from '../../rx/rxState';

const InputStyled = styled.input`
  color: black;
  width: 100%;
  font-size: large;
  border: 1px solid #dadada;
  padding: 10px;
  ${(props) =>
    props.type === 'checkbox'
      ? `
        border: 1px solid lightgray;
        width: 20px;
        height: 20px;
        transform: scale(1.3);
        margin: auto; 
        display: block;`: null}
  }
  ${(props) =>
    props.enabled ? 'pointer-events: auto;' : 'pointer-events: none;'}
  ${(props) =>
    props.state === 'invalid'
      ? 'border-color: #ff5172; border-width: 2px;'
      : null}

  &.shake {
    animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97);
  }

  @keyframes shake {
    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }

    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }

    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }

    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }
`;

export class BlockFormProxy extends React.Component {
  constructor(props) {
    super(props);
    this.onKeyUp = this.onKeyUp.bind(this);
    this.inputRef = React.createRef();
    this.state = {
      inputValue: null,
      inputState: 'null', // 'null' means empty, 'invalid' - means empty or invalid (email format for example), 'good' - means good
    };
  }

  componentDidUpdate(prevProps) {
    // if(prevProps.styleText !== this.props.styleText){
    this.props.reference.current.style.cssText = this.props.styleText();
    // }
  }

  componentDidMount() {
    this.props.reference.current.style.cssText = this.props.styleText();
    this.props.callback({
      verify: () => this.verify(),
    });

    if (StoreController.instance().liveMode) {
      let type = this.props.formType.replace(/\s+/g, '');
      let value = this.loadFromCache(type);
      this.setState({ inputValue: value });
    }
  }

  verify() {
    let value = this.inputRef.current.value;
    if (value === '' && this.props.formType !== 'Checkbox') {
      this.setState({ inputState: 'invalid' });
      return false;
    } else if (
      this.props.formType === 'Email' &&
      !emailValidator.validate(value)
    ) {
      this.setState({ inputState: 'invalid' });
      return false;
    } else if (
      this.props.formType === 'Checkbox' &&
      this.inputRef.current.checked !== true
    ) {
      this.setState({ inputState: 'invalid' });

      return false;
    }
    this.setState({ inputState: 'good' });

    let type = this.props.formType.replace(/\s+/g, '');
    this.saveToCache(type, value);

    return {
      type: type,
      value: value,
    };
  }

  loadFromCache(type) {
    let key = `${type}-formValue`;
    // console.log('loading from cache for key:', key);
    return localStorage.getItem(key);
  }

  saveToCache(type, value) {
    let key = `${type}-formValue`;
    // console.log('saving in to cache for key:', key);
    localStorage.setItem(key, value);
  }

  verifyOnBlur(e) {
    if (this.state.inputState === 'invalid') {
      this.verify();
    }
  }

  onChange(e) {
    let value = this.inputRef.current.value;
    this.setState({ inputValue: value });
  }

  onKeyUp(e) {
    if (e.keyCode === 13) {
      //find Button Block
      let element = document.getElementsByClassName('BlockButton');
      if (element.length > 0) {
        element[0].querySelector('#ButtonClickElement').click();
      }
    }
  }

  onAnimationEnd(e) {
    if (this.props.formType === 'Checkbox') {
      this.setState({
        inputState: 'null',
      });
    }
  }

  render() {
    let placeholder = 'Enter Your ' + this.props.formType;
    let type = this.props.formType === 'Checkbox' ? 'checkbox' : 'text';
    return (
      <BlockStyle
        id={this.props.id}
        key={this.props.id}
        style={this.props.style}
        ref={this.props.reference}
        className={this.props.className}
      >
        <InputStyled
          className={
            this.state.inputState === 'invalid' &&
            this.props.formType === 'Checkbox'
              ? 'shake'
              : ''
          }
          ref={this.inputRef}
          state={this.state.inputState}
          type={type}
          onClick={(e) => e.stopPropagation()}
          onKeyUp={(e) => this.onKeyUp(e)}
          onBlur={(e) => this.verifyOnBlur(e)}
          onChange={(e) => this.onChange(e)}
          placeholder={placeholder}
          value={this.state.inputValue}
          onAnimationEnd={(e) => this.onAnimationEnd(e)}
          enabled={StoreController.instance().liveMode}
        />
      </BlockStyle>
    );
  }
}

export class BlockForm extends Block {
  constructor(props) {
    super(props);
    this.type = 'Form';
    // this.formType = 'Name';
    this.callback = null;
    let attr = {
      id: 'formType',
      displayName: 'Type',
      value: 'Email',
      type: 'AttributeDropdown',
      options: [
        {
          label: 'Email Address',
          value: 'Email',
        },
        {
          label: 'Full Name',
          value: 'Name',
        },
        {
          label: 'First Name',
          value: 'FirstName',
        },
        {
          label: 'Last Name',
          value: 'LastName',
        },
        {
          label: 'Phone Number',
          value: 'PhoneNumber',
        },
        {
          label: 'Checkbox',
          value: 'Checkbox',
        },
      ],
    };
    this.addAttribute(attr);
  }

  verifyInput() {
    if (!this.callback) {
      return {};
    } else {
      return this.callback.verify();
    }
  }

  pack() {
    let data = super.pack();
    data['formType'] = this.formType.value;
    return data;
  }

  unpack(data) {
    super.unpack(data);
    let type = data['formType'];
    if (type !== undefined) {
      this.formType.value = type;
    }
  }
  callbackHandler(callback) {
    this.callback = callback;
  }

  renderView() {
    return (
      <Fragment key={this.id}>
        {this.isVisible ? (
          <>
            {/* { this.isDragging ? this.getPlaceholder() */}
            {/* : */}
            <BlockFormProxy
              id={this.id}
              key={this.id}
              reference={this.ref}
              ref={this.proxyRef}
              styleText={this.style}
              formType={this.formType.value}
              callback={(callbacks) => this.callbackHandler(callbacks)}
              className={this.className}
            ></BlockFormProxy>
            {/* } */}
          </>
        ) : null}
      </Fragment>
    );
  }
}
